import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  image?: ImageProps
  title?: string
}

export const Stripe = memo(function Stripe({
  description,
  image,
  title,
}: Props) {
  return (
    <Container row wrap stretch>
      <LeftSide dial={4}>
        <Wrapper>
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
        </Wrapper>
      </LeftSide>
      <RightSide>
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: #edf4f1;
  position: relative;
  &:before {
    content: '';
    border: 3px solid ${({ theme }) => theme.colors.variants.neutralLight4};
    opacity: 0.3;
    position: absolute;
    top: 4.2vw;
    right: 4.2vw;
    bottom: 4.2vw;
    left: 4.2vw;
    z-index: 2;
  }
  &:nth-of-type(even) {
    > div {
      &:first-of-type {
        order: 2;
      }
    }
  }

  @media (max-width: 1023px) {
    &:before {
      display: none;
    }
  }
`

const LeftSide = styled(FlexBox)`
  width: 50%;
  padding: 7.0625rem 8.056vw;

  @media (max-width: 1023px) {
    width: 100%;
    order: 2;
    padding: 3.75rem 1.9375rem;
  }
`

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 500;
  font-size: 3.0625rem;

  @media (max-width: 1023px) {
    font-size: 30px;
  }
`

const Description = styled.div`
  margin-top: 1.875rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 0.9375rem;
  line-height: 1.75rem;

  strong {
    font-weight: 600;
  }
`

const RightSide = styled(FlexBox)`
  width: 50%;
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  padding-bottom: 40%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding-bottom: 70%;
  }
`
